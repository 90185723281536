
/**
 * fa il parse di search query su url 
 */
export const getParameterByName=function(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

//costante pubblica per api stripe
export const getKEYStripe = function(){
    //return "pk_test_51J4jNoDglWvKnQf3SD9aF8VzYQGMJsA0QhY32J9QfjhzcakocLprEAkNV9y19Lxyk1Z4VnV350tWgXV0yZxS81VS00GBUy0rbW";
    return "pk_live_51J6WJoHikxctxMTLc0ujPdtTsPZhsnnFnm6qHx9GzZrk1WBWpzQxk4LcJrXJZTouQUVybaUso47R48A573zBo2YI00wjmQukjo";
}




