import React from "react";
import {Container,Row,Col,Button,Card,CardBody} from "reactstrap";

class Anagrafiche extends React.Component {
    render() {
        return (
            <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-lg section-shaped pb-50">
              <div className="shape shape-style-1 shape-primary">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>
              <Container  className="py-lg-md d-flex" >
                <div className="col px-0">                  
                    
                </div>
              </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            <section className="section pb-50">
                <Container >
                    <Row className="row-grid align-items-center">                        
                        <Col className="" lg="4" sm="12">
                            <Card className="card-lift--hover shadow border-0">
                                <CardBody className="py-5">
                                {/*<div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                                    <i className="ni ni-check-bold" />
                                </div>*/}
                                <h6 className="text-primary text-uppercase">Abbonamenti </h6>
                                <p className="description mt-3">
                                Gestisci gli abbonamenti in anagrafica, aggiungi modifica i prezzi,
                                assegna gli abbonamenti agli utenti.
                                </p>                         
                                <Button
                                    className="mt-4"
                                    color="primary"
                                    href="/#/dashboard/anagraficheabbonamenti"
                                    
                                >
                                    VAI
                                </Button>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col className="" lg="4" sm="12">
                            <Card className="card-lift--hover shadow border-0">
                                <CardBody className="py-5">
                                {/*<div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                                    <i className="ni ni-check-bold" />
                                </div>*/}
                                <h6 className="text-primary text-uppercase">Eventi </h6>
                                <p className="description mt-3">
                                Gestisci gli eventi creati dagli utenti, guarda le statistiche,
                                abilita disabilita eventi.
                                </p>                         
                                <Button
                                    className="mt-4"
                                    color="primary"
                                    href="/#/dashboard/anagraficheeventi"
                                    
                                >
                                    VAI
                                </Button>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col className="" lg="4" sm="12">
                        <Card className="card-lift--hover shadow border-0">
                                <CardBody className="py-5">
                                {/*<div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                                    <i className="ni ni-check-bold" />
                                </div>*/}
                                <h6 className="text-primary text-uppercase">Utenti </h6>
                                <p className="description mt-3">
                                Gestisci gli utenti della piattaforma, associa gli abbonamenti,
                                blocca/sblocca utente, controlla i pagamenti.
                                </p>   {/*onClick={e => e.preventDefault()}*/}                      
                                <Button
                                    className="mt-4"
                                    color="primary"
                                    href="/#/dashboard/anagraficheutenti"
                                    
                                >
                                    VAI
                                </Button>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
        )
    }
}

export default Anagrafiche;