
import React,{Fragment} from "react";
import ReactDatetime from 'react-datetime';


import {
  Container,
  Row,
  Col,
  CustomInput,
  Table,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Nav,
  Badge,
  Button,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label
} from "reactstrap";
import {Formik,Field,ErrorMessage} from "formik";

import swal from "sweetalert2"
import moment from 'moment'
//pagination 
import Pagination from "react-js-pagination";

//importo helpers per utente
import sub from "../../helpers/subscriptions.js";
import { getKEYStripe } from "helpers/utils.js";

//stripe 
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { CheckoutForm } from "../../helpers/CheckoutForm";
const PUBLIC_KEY = getKEYStripe();
const stripeTestPromise = loadStripe(PUBLIC_KEY);

//require("bootstrap/less/bootstrap.less");



class StoricoPagamenti extends React.Component {


  constructor(props){
    super(props);

    this.state={
      isPayed:false,
      subscriptions:[],
      selectedAbb:[],
      selectedSub:{},
      payments:[],
      activePage: 1,
      itemPerPage: 10,
      isModalPagamentiOpen:false
    }

    this.refreshSubscriptions = this.refreshSubscriptions.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handlePagamento = this.handlePagamento.bind(this)
    this.toggleModalPagamenti = this.toggleModalPagamenti.bind(this)
    this.getPaymentsByIDAbb = this.getPaymentsByIDAbb.bind(this)
  }

  refreshSubscriptions(e){
    var isPayed = false;
    if(e){
      e.persist()
      //console.log("allusers",e.target.checked)
      isPayed = e.target.checked
      this.setState({isPayed:isPayed})
    }
    sub.getUserSubscriptions(isPayed).then(res=>{
      console.log("res",res)
      this.setState({subscriptions:res})
    }).catch(err=>{
      console.log("err",err)
    })
  }

  //gestisce pagamento 
  handlePagamento(e,item){
    this.toggleModalPagamento(e,item)
    /*swal.fire({
      title: 'In sviluppo',
      text: "La funzione di pagamento è in fase di test, per procedere al pagamento invia una mail dal form di contatti!" ,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Conferma'
    }).then(res=>{

    })
    */
  }

  //richiede invio fattura per mail 
  requestFattura(e,item){
    //invio a backend richista di invio fattura proforma 
    //...

    swal.fire({
      title: 'Inviata',
      text: "E' stata inviata una mail alla tua casella postale con la fattura proforma!" ,
      icon: 'success',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK'
    }).then(res=>{

    })
  }

  getPaymentsByIDAbb(IDAbb,isAll){
    //invoco api per ottnere lista di pagamenti collegati all'abbonamento 
    sub.getPaymentsByIDAbb(IDAbb,isAll).then(payments=>{
      //console.log("pagamenti per utente selezionato ",payments)
      this.setState({payments:payments})
    }).catch(err=>{

    })
  }

  toggleModalPagamenti(e,item){
    //apre popup con riassunto pagamenti per quell'abboamento
    this.setState({isModalPagamentiOpen:!this.state.isModalPagamentiOpen})

    if(item){
      this.setState({selectedAbb:item})
      //scarico pagamenti di id abbonamento selezionato 
      this.getPaymentsByIDAbb(item.IDAbb)

    }
  }

    //funzione per far comparire modal pagamento 
    toggleModalPagamento = (e,item)=>{
      //se sto aprendo modal , seleziono elemento
      if(!this.state.isModalPagamentoOpen){
        this.setState({selectedSub:item})
      }
      this.setState({isModalPagamentoOpen:!this.state.isModalPagamentoOpen})
    }
  
    //callback per pagamento failed 
    onFailed=(errMsgOut)=>{
      console.log("obj out failed: ",errMsgOut)
      //mostro errore
      swal.fire("Errore",errMsgOut,"error")
    }
  
    //callback per pagamento ok
    onOk=(objOut)=>{
      console.log("obj out ok: ",objOut)
      //mostro messaggio di successo 
      swal.fire("Fatto","Transazione avvenuta con successo","success")
      //chiudo popup 
      this.toggleModalPagamento();
      //riscarico subscription
      this.refreshSubscriptions()
      //riscarico i pagamenti 
      this.getPaymentsByIDAbb(this.state.selectedSub.IDAbb)

    }

  handlePageChange(pageNumber){
    //console.log("PAGE NUMBER",pageNumber)
    this.setState({ activePage: pageNumber });
  }

  componentDidMount(){
    this.refreshSubscriptions()
  }




    render() {
      const { subscriptions, activePage, itemPerPage } = this.state;

      const indexOfLastTodo = activePage * itemPerPage;
      const indexOfFirstTodo = indexOfLastTodo - itemPerPage;
      const renderedSubscriptions = subscriptions.slice(indexOfFirstTodo, indexOfLastTodo);

        return (
            <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-md section-shaped pb-10">
              <div className="shape shape-style-1 shape-primary">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>
              <Container fluid className="py-lg-md d-flex" >
                <div className="col px-0">
                  <Row>
                    <Col lg="6">                        
                        <p className="pl-4 lead text-white">
                         Storico Pagamenti <span className="badge badge-secondary">{this.state.subscriptions.length}</span>                         
                        </p> 
                        <Button className="ml-4" href="/#/acquista">Acquista </Button>                       
                    </Col>
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            
            <section className="section pb-10 pt-0">
              <Container fluid className="p-5">
                <Row>
                  <Col>
                  <Card className=" shadow border-0">
                      <CardHeader>
                        <Row>
                          <Col>
                            <CustomInput className="ml-4" type="switch" id="exampleCustomSwitch" name="customSwitch" label="Mostra Pagati" onChange={this.refreshSubscriptions}/>
                          </Col>
                          <Col className="text-right">
                            {!this.state.isAllUsers?
                            <span></span>  
                            :
                            <Button color="default" size="sm" outline type="button" onClick={this.toggleModalEdit}>                      
                              Nuovo
                            </Button>
                            }
                          </Col>
                        </Row>  
                      </CardHeader>
                        <CardBody className="p-0">
                        <Table className="mb-0" size="sm" hover striped responsive>{/* size="sm"*/}
                          <thead>
                            <tr>
                              {/*<th></th>*/}
                              <th>ID </th>
                              <th>Mail</th>
                              <th>Tipo Abbonamento</th>
                              <th>Costo</th>
                              {/*<th>Costo Mensile</th>*/}
                              <th>Data Pagamento</th>
                              <th>Ref. Pagamento</th>
                              <th>Data Inizio</th>
                              <th>Data Di Scandenza</th>
                              <th style={{"minWidth":"130px"}}></th>
                            </tr>
                          </thead>
                          <tbody>
                            {renderedSubscriptions.map((item,i)=>{
                              return <tr key={i}>
                                  {/*<td>
                                    <Button outline
                                      className=" btn-icon btn-simple"
                                      color="info"
                                      size="sm"
                                      type="button"
                                      onClick={(e)=>{this.toggleModalPagamenti(e,item)}}
                                      >
                                        <i className="fa fa-search"> </i> 
                                    </Button>
                                  </td>*/}
                                  <th scope="row">
                                    {item.ID ? (
                                      <small><b>{item.IDAbb}</b></small>
                                    ) : (
                                      <small id={"TooltipAL"+i}><i className="text-red fa fa-exclamation-triangle" aria-hidden="true"></i>
                                      <UncontrolledTooltip placement="right" target={"TooltipAL"+i}>
                                        Abbonamento non esistente
                                      </UncontrolledTooltip>
                                      </small>
                                    )}                                                                      
                                  </th>
                                  <td>                                   
                                    {
                                      item.Mail ? (
                                        <small>{item.Mail}</small>
                                      ) : (
                                        <small className="text-red" id={"TooltipML"+i}><i className="text-red fa fa-exclamation-triangle" aria-hidden="true"></i> Utente non Esistente
                                        <UncontrolledTooltip placement="right" target={"TooltipML"+i}>
                                          Utente che ha sottoscritto un abbonamento non più esistente
                                        </UncontrolledTooltip>
                                        </small>
                                      )} 
                                    
                                    </td>
                                  <td><small>{item.Title}</small></td>
                                  <td><small>{item.Price}€</small></td>
                                  {/*<td><small>{item.PricePerMonth}€</small></td>*/}
                                  <td>{                                      
                                      item.PayedDate?(
                                        <small className="">{moment(item.PayedDate).format("DD-MM-YYYY")}</small>
                                      ):(
                                        <span></span>
                                      )
                                  }</td>                                  
                                  <td><code>{item.PayPalID}</code></td>
                                  <td>{
                                    item.DateStart?(
                                      <small className="">{moment(item.DateStart).format("DD-MM-YYYY")}</small>
                                    ):(
                                      <span></span>
                                    )
                                  }</td>
                                  <td>{
                                    item.ExpireOn?(
                                      <small className="">{moment(item.ExpireOn).format("DD-MM-YYYY")}</small>
                                    ):(
                                      <span></span>
                                    )
                                  }</td>
                                  <td className=" td-actions " style={{minWidth:'150px'}}>                                    
                                    {item.PayedDate?(
                                      <div>
                                        <Button outline
                                          className=" btn-icon btn-simple"
                                          color="success"
                                          size="sm"
                                          type="button"
                                        ><i className="fa fa-check"></i>Pagato</Button>
                                        <Button outline
                                          className=" btn-icon btn-simple"
                                          color="info"
                                          size="sm"
                                          type="button"
                                          onClick={(e)=>{this.requestFattura(item)}}
                                        >
                                          Richiedi fattura
                                      </Button>
                                      </div>
                                    ):(
                                      <div>
                                        <Button outline
                                          className=" btn-icon btn-simple"
                                          color="warning"
                                          size="sm"
                                          type="button"
                                        ><i className="fa fa-exclamation-triangle"></i> Da Pagare
                                        </Button>
                                        <Button outline
                                          className=" btn-icon btn-simple"
                                          color="info"
                                          size="sm"
                                          type="button"
                                          onClick={(e)=>{this.handlePagamento(e,item)}}
                                        >
                                          Paga
                                      </Button>
                                      </div>
                                    )}
                                  </td>
                                </tr>
                            })}                                                      
                          </tbody>
                        </Table>
                        </CardBody>
                        <CardFooter>
                          <Nav className="justify-content-center">
                            <Pagination
                                  hideNavigation
                                  activePage={this.state.activePage}                                  
                                  itemsCountPerPage={this.state.itemPerPage}
                                  totalItemsCount={this.state.subscriptions.length}
                                  pageRangeDisplayed={5}
                                  onChange={this.handlePageChange}
                                  itemClass="page-item"
                                  linkClass="page-link"                                 
                              />
                            </Nav>
                        </CardFooter>
                        </Card>
                  </Col>
                </Row>
              </Container>
            </section>
            {/*************       MODAL popup gestione pagamenti        ***************/}
            <Modal isOpen={this.state.isModalPagamentiOpen} toggle={this.toggleModalPagamenti} size="lg">
                    <ModalHeader toggle={this.toggleModalPagamenti}>Pagamenti per {this.state.selectedAbb.Mail} <Badge color="secondary">{this.state.payments.length}</Badge></ModalHeader>
                    <ModalBody className="p-0">
                      <Container fluid>
                        <Row className="mt-1 mb-1">
                          <Col>
                            <CustomInput className="ml-5" type="switch" id="CustomSwitchPay" name="customSwitchPay" label="Mostra tutti" onChange={this.refreshPayments}/>
                          </Col>
                          <Col className="text-right">
                            {/* inserisce nuovo pagamento  */}
                            <Button color="default" size="sm" outline type="button" onClick={this.toggleModalEditPayments}>                      
                              Nuovo Pagamento
                            </Button>
                          </Col>
                        </Row>
                        <Row>
                          {/* tabella con pagamenti   per abbonamento selezionato */}
                          <Table className="mb-0" size="sm" hover striped responsive>{/* size="sm"*/}
                            <thead>
                              <tr>                              
                                <th>#</th>     
                                <th>ID Stripe</th>  
                                <th>Prezzo</th>
                                <th>Data Pagamento</th> 
                                <th>Data Inizio</th>
                                <th>Data Fine</th>
                                <th>Stato</th>
                                <th>Visibile</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.payments.map((item,i)=>{
                                return <tr key={i}>
                                          <td><small>{item.ID}</small></td>
                                          <td><small>{item.PayPalID}</small></td>
                                          <td><small>{item.Price}</small></td> 
                                          <td>
                                            {item.PayedDate?
                                            <small>
                                              {moment(item.PayedDate).format('DD-MM-YYYY')}
                                            </small>
                                            :
                                            <span></span>
                                            }
                                          </td>                                        
                                          <td><small>{moment(item.DateStart).format('DD-MM-YYYY')}</small></td>
                                          <td><small>{moment(item.ExpireOn).format('DD-MM-YYYY')}</small></td>
                                          <td>

                                            {item.PayedDate? 
                                            <Button outline
                                              className=" btn-icon btn-simple"
                                              color="success"
                                              size="sm"
                                              type="button"
                                              onClick={(e)=>{this.togglePaymentState(item)}}
                                            >
                                              <i className="fa fa-check"></i> PAGATO
                                            </Button>:
                                            <Button outline
                                                className=" btn-icon btn-simple"
                                                color="warning"
                                                size="sm"
                                                type="button"
                                                onClick={(e)=>{this.togglePaymentState(item)}}
                                              >
                                                <i className="fa fa-exclamation-triangle"></i> DA PAGARE
                                            </Button>
                                            }
                                          </td>
                                          <td>
                                            {item.isEnabled==1?
                                            <i className="fa fa-check text-success" onClick={(e)=>this.togglePaymentEnabled(item.ID,false)}></i>
                                            :
                                            <i className="fa fa-times text-danger" onClick={(e)=>this.togglePaymentEnabled(item.ID,true)}></i>
                                            }
                                          </td>
                                    </tr>
                              })
                            }
                            </tbody>
                          </Table>
                        </Row>
                        </Container>
                    </ModalBody>
                    <ModalFooter>

                    </ModalFooter>
                </Modal>
                {/* definisco modal dove ospitare form pagamento  */}
                <Modal isOpen={this.state.isModalPagamentoOpen}  size="" backdrop="static"> {/* toggle={this.toggleModalPagamento} */}
                    <ModalHeader toggle={this.toggleModalPagamento} className="">Acquista: {this.state.selectedSub.Title} - <b>{this.state.selectedSub.Price}</b>€</ModalHeader> 
                    <ModalBody className="p-2">
                      <Container fluid>                        
                        
                          {/* tabella con pagamenti   per abbonamento selezionato 
                          customer={"cliente"}
                            customerMail={"mailcliente@cliente.it"}
                            */}
                          <Elements stripe={stripeTestPromise} >
                            <CheckoutForm 
                            amount={this.state.selectedSub.Price} 
                            IDRefAbb={this.state.selectedSub.IDAbb}
                            IDRefPagamento={this.state.selectedSub.ID}
                            onOk={this.onOk} 
                            onFailed={this.onFailed} 
                            description={this.state.selectedSub.Title}
                            
                            />
                          </Elements>
                          
                        
                        </Container>
                    </ModalBody>              
                </Modal>
        </div>
        )
    }
}

export default StoricoPagamenti;