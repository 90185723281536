/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import nosleep from "nosleep.js";
import React from "react";
import io from "socket.io-client"
import moment from "moment"
import swal from "sweetalert2"
import QRCode from "qrcode.react";
import $ from 'jquery';
import {getParameterByName} from "../../helpers/utils.js"
//importo helpers per utente
import evt from "../../helpers/events";


// nodejs library that concatenates classes
//import classnames from "classnames";

// reactstrap components
import {

  Button,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ListGroup,
  ListGroupItem,
  Label,
  UncontrolledTooltip,
  UncontrolledCollapse,
  Card,
  CardBody,
  Modal,
  ModalBody
} from "reactstrap";


var socket;
var ii;

var streamConstraints = { audio: {
    /*autoGainControl: false,
    channelCount: 2,
    echoCancellation: false,
    latency: 0,
    noiseSuppression: false,
    sampleRate: 48000,
    sampleSize: 16,*/
    echoCancellation: true,
    volume: 1.0
    }, video: { height:480,deviceId:{exact:""}  } 
};

var iceServers = {
    iceServers: [
        //{ urls: "stun://stun.services.mozilla.com" },
        //{ urls: "stun:64.233.167.127:19302" }, //stun stun://stun.l.google.com:19302 //manage.e-sopo.com = 157.230.117.88
        {urls:"turn:134.122.81.49:3478",//"turn:157.230.117.88:3478",
        'credential': 'passwordz',
        'username': 'usernamez'
    }
    ],
};

let user;
let rtcPeerConnections = {};

const AudioContext = window.AudioContext || window.webkitAudioContext;
const audioContext = new AudioContext();
var initialized = false;

class Test extends React.Component {

    constructor(props){
      

      //facico parse di elementi su url 
      var idP;
      if(props.history){
        idP = getParameterByName('id',props.history.location.search)
      }
      console.log("CONSTRUCTOR IDP",idP)

      console.log("GestioneEvento --> props",props)
      //associo numero delal stanza se passato da props.query.id

        super(props)
        this.videoRef = React.createRef()
        this.state = {
            nome:'',
            stanza:props.id||idP||'',
            conditions:{},
            socket:'',
            isStreaming:false,
            handsUpStr:"",
            isBroadcaster:false,
            isMuted:true,
            streamObj:new MediaStream(),
            playsInline:true,
            questions:[],
            questionsToRead:0,
            viewers:[],
            events:[],
            isBroadcasterOnLine:false,
            broadcasterName:'',
            onlyAudio:true,
            isStreamingOnlyAudio:true,
            isTrackStopped:false,
            isEnded:false,
            isGuest:props.id?true:false,
            chkAudio:true,
            chkVideo:false,
            intervalCHKPause:undefined,
            bckSignal:'',
            audioIn:[],
            videoIn:[],
            audioOut:[],
            isModalQRCode:false,
            URLQRcode:''
        };

        this.handleVideoChange = this.handleVideoChange.bind(this);
        this.TestAudioVideo = this.TestAudioVideo.bind(this);
        this.BuildListAudioVideo = this.BuildListAudioVideo.bind(this);
        this.RequestFullScreen = this.RequestFullScreen.bind(this);
        this.getClassSignal = this.getClassSignal.bind(this)
        this.toggleModalQRCode = this.toggleModalQRCode.bind(this)
        this.nosleep = new nosleep()
        

        
    }
   
    //aggiunge domande a array domande 
    addQuestion(description,user,dt){
      var qtr = this.state.questionsToRead
      var qq = this.state.questions
      qq.push({description,user,dt})
      qtr++;
      this.setState({questions:qq})
      this.setState({questionsToRead:qtr})
    }

    //aggiungo evento a storico eventi 
    addEvent(description){
      var vv = this.state.events
      vv.push({description:description,time:new Date()})
      this.setState({events:vv})
    }
    
    //quando componente viene cancellato invio trigger di utente sconnesso 
    componentWillUnmount(){
      this.nosleep.enable()
      window.removeEventListener('beforeunload', this.onUnmount, false);
      this.onUnmount()
      
    }

    //eseguita alla chiusura della finestra o all'unmount del componente
    onUnmount (){
      //elimino timer di refresh 
      if(ii)
        clearInterval(ii)
      this.nosleep.disable()

      //rimuovo eventi 
      window.removeEventListener('online',function(){})
      window.removeEventListener('offline',function(){})

      if(user)
        socket.emit('disconnectinguser',{room: user.room,username:user.name})

      //se utente broadcaster invio flag a db di utente non più in trasmissione 
      if(this.state.isBroadcaster){
        evt.toggleBroadcasterOnLine(this.state.stanza,false).then().catch();
        //metto in pausa streaming se esco dalla pagina
        this.PauseStreaming()
        //invio evento socket broadcasteroutofroom per far uscire anche i visitatori e farli rientrare quand ron broadcaster
        socket.emit('broadcasteroutofroom',{room: user.room,username:this.state.broadcasterName})

      }
    }

    /*componentDidUpdate(props){
      //this.props = props;
      //console.log("update ",props)
      var idP = getParameterByName('id',props.history.location.search)
      console.log("UPDATE IDP",idP)
      if(this.state.stanza!=idP){
        
        this.setState({stanza:idP})
        //this.state.stanza=idP
      }
    }*/

  componentDidMount() {  
    this.nosleep.enable()

    //al change di connection triggera funzione per cambiare sfondo 
    if(navigator.connection){
      navigator.connection.onchange = (e)=>{
        this.getClassSignal()
      }
      //console.log("init class signal")
      this.getClassSignal()
    }

    //evento disconessione 
    window.addEventListener('online',function(){
      _t.addEvent("ESOPO: sei ritornato online")
      //se è un broadcaster rifaccio partire video 
      //_t.videoRef.current.load()
      //_t.videoRef.current.pause() 
      _t.videoRef.current.play() 

    },false)

    window.addEventListener('offline',function(){
      _t.addEvent("ESOPO: risulti offline, hai controllato la tua connessione?")
      

    },false)

    //this.$ = $;
    window.addEventListener('beforeunload', this.onUnmount, false);
    /*var player = document.getElementsByTagName("video")[0];
    player.addEventListener('webkitbeginfullscreen', ()=>{ alert('webkitbeginfullscreen')});
    player.addEventListener('webkitendfullscreen', ()=>{alert(' webkitendfullscreen ')});*/

    //se è guest trigger periodicamente status evento per leggere lo stato e avviare o meno streaming 
    if(this.state.isGuest){
      //invoco api per sapere se evento è nello stato abilitato e started per poter premere join 
      evt.getStatus(this.state.stanza).then(rr=>{
        console.log("rr",rr)
        this.setState({conditions:rr})
        //se la stanza ancora non è stata avviata faccio partire timer 
        if((rr.isStarted==false || rr.isBroadcasterOnLine==false) && rr.isCompleted==false){
          var cc = setInterval(()=>{
            evt.getStatus(this.state.stanza).then(rr=>{
              console.log("rr in timer ",rr)
              this.setState({conditions:rr})
            })

            if(this.state.conditions.isStarted==true && this.state.conditions.isBroadcasterOnLine==true ){
              clearInterval(cc);
              //se utente non ha inserito nome inserisco un utente casuale 
              /*if (this.state.nome=='' || ! this.state.nome)
                this.setState({nome:'user'+Math.random(1)})
                */
              //messaggio stanza avviata 
              swal.fire({
                title: 'Stanza Pronta!',
                text: 'La stanza è ora disponibile per lo streaming, clicca "entra" per partecipare allo streaming',
                icon: 'success',                
                confirmButtonText: 'entra',
                allowOutsideClick:false,
                input: 'text',
                inputLabel: 'Nome', 
                inputValidator: (value) => {
                  if (!value) {
                    return 'Scrivi un nome valido'
                  }/*else{
                    return {nome:value}
                  }*/
                }
              }).then(result=>{
                console.log("result",result)
                if(result.isConfirmed){
                  this.setState({nome:result.value})
                  this.joinAsViewer()
                }
              })
            }
          },5000)

        }else{
          //evento già concluso , utente non può partecipare
          if(rr.isCompleted==true){
            swal.fire({
              title: 'Evento Concluso',
              text: 'L\'evento è già stato concluso dalla guida ! ',
              icon: 'info',
              confirmButtonText: 'chiudi',
              allowOutsideClick:false
            })
          }
        }
      }).catch(err=>{
        console.log("err",err)
      })
    }else{
      //scarico info per mostrare nome evento
      evt.getStatus(this.state.stanza).then(rr=>{
        console.log("rr broadcaster",rr)
        this.setState({conditions:rr})
      }).catch(rr=>{

      })
    }

    ///////////////////////////////// FUNZIONI PER STREAMING /////////////////////////////

    socket=io({
      timeout:300000,
      //transports: ["websocket"], 
      forceNew: true //crea una nuova istanza ogni volta che si riconnette 
    })
    var _t = this;
/*
    socket.on('connect', (e) => {
        // ...
        console.log("connected",e)
    });*/

    //refresh riconnessione ogni 45 minuti seondi 
    ii = setInterval(()=>{
      if(_t.state.conditions.isStarted==true && _t.state.conditions.isBroadcasterOnLine==true && _t.state.isBroadcaster==false)
        _t.joinAsViewer()
    }, 60000*45) //

    socket.on('disconnect',()=>{
      //triggero riconeessione 
      /*socket=io({ //'ws://localhost:8080/',
        timeout:300000,
        //transports: ["websocket"], 
        forceNew: true //crea una nuova istanza ogni volta che si riconnette 
      })
      */
    })

    

    //costruisco array dispositivi audio video , solo se è un utente streaming 
    //if(this.state.isBroadcaster)
    if(!this.state.isGuest){
      //chiedo permessi per utilizzare microfono e video 
      ///////////////////////////////////
      var fnUserMedia = ((navigator.mediaDevices && navigator.mediaDevices.getUserMedia))// || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia);

      //console.log("non son guest")
      if(navigator.mediaDevices && !navigator.mediaDevices.enumerateDevices){
        if(fnUserMedia){
          //console.log("chiedo test beneeeeee")
          this.TestAudioVideo()
        
        }else{
          swal.fire("Attenzione",'getUserMedia non supportata!','error')
        }
      }else{
        if(navigator.mediaDevices){
          //console.log("dovrei costruire listaaaaaaaa")
          navigator.mediaDevices.enumerateDevices().then(function(devices) {
            //console.log("devicessssss",devices)
            if(devices.length==0)
              _t.TestAudioVideo()
            else{
              if(devices[0].deviceId=='')
                _t.TestAudioVideo()
              else
              _t.BuildListAudioVideo()
            }
          })
            
        }else{
          console.log("testo audio videooooo")
            this.TestAudioVideo()
        }
      }

      //////////////////////////////////
    }else{

    }
    
    socket.on('disconnectedviewer',function(viewer){
      //aggiungo evento disconnessione
      console.log("viewer",viewer)
    })

    socket.on('broadcasteroutofroom',function(viewer){
      //aggiungo evento disconnessione
      console.log("broadcasteroutofroom",viewer)
      //avvio timer per aspetttare si riconnetta broadcaster 
      var cc = setInterval(()=>{
        evt.getStatus(_t.state.stanza).then(rr=>{
          console.log("rr in timer ",rr)
          _t.setState({conditions:rr})
        }).finally(()=>{

          if(_t.state.conditions.isStarted==true && _t.state.conditions.isBroadcasterOnLine==true ){
            clearInterval(cc);
            //se utente non ha inserito nome inserisco un utente casuale 
            if (_t.state.nome=='' || ! _t.state.nome)
              _t.setState({nome:'user'+Math.random(1)})
            //messaggio stanza avviata 
            swal.fire({
              title: 'Stanza Pronta!',
              text: 'La stanza è ritornata disponibile per lo streaming, clicca "entra" per ritornare allo streaming',
              icon: 'success',
              confirmButtonText: 'entra',
              allowOutsideClick:false
            }).then(result=>{
              if(result.value){
                _t.joinAsViewer()
              }
            })
          }
        })
      },5000)

    })


    // message handlers
    socket.on("new viewer", function (viewer) {
    rtcPeerConnections[viewer.id] = new RTCPeerConnection(iceServers);

    const stream = _t.videoRef.current.srcObject;


    stream
        .getTracks()
        .forEach((track) => {
            
        
        
        rtcPeerConnections[viewer.id].addTrack(track, stream)
        /* modifica compatiobilità iphone 
        rtcPeerConnections[viewer.id].addTransceiver('video', { direction: 'sendonly'}).sender.replaceTrack(track);    
        // Create a transceiver to receive video.
        rtcPeerConnections[viewer.id].addTransceiver('video', { direction: 'recvonly'});
        fine  modifica compatibilità iphone */
        });

    rtcPeerConnections[viewer.id].onicecandidate = (event) => {
        if (event.candidate) {
            console.log("sending ice candidate");
            socket.emit("candidate", viewer.id, {
                type: "candidate",
                label: event.candidate.sdpMLineIndex,
                id: event.candidate.sdpMid,
                candidate: event.candidate.candidate,
            });
        }
    };

    

    rtcPeerConnections[viewer.id]
        .createOffer()
        .then((sessionDescription) => {
        rtcPeerConnections[viewer.id].setLocalDescription(sessionDescription);
        socket.emit("offer", viewer.id, {
            type: "offer",
            sdp: sessionDescription,
            broadcaster: user,            
        });
        })
        .catch((error) => {
        console.log(error);
        });

    //let li = document.createElement("li");
    //li.innerText = viewer.name + " e' entrato";
    var vv = _t.state.viewers
    //faccio push solo se nome non è in lista 
    if(vv.indexOf(viewer.name)==-1){
      vv.push(viewer.name)
      _t.setState({viewers:vv})
      //aggiungo evento tizio si è connesso come visitatore 
      _t.addEvent(viewer.name+" si è connesso!")
    }

    });

    socket.on("candidate", function (id, event) {
      var candidate = new RTCIceCandidate({
          sdpMLineIndex: event.label,
          candidate: event.candidate,
      });
      try{
        rtcPeerConnections[id].addIceCandidate(candidate);
      }catch(erricecan){
        console.log("errore in aggiungere ice candidate , probabilmente dopo disconnessione")
        //_t.videoRef.current.load()
        //_t.videoRef.current.pause() 
        _t.videoRef.current.play()
      }
      console.log("aggiunto candidato, socket.onice candidate")
    });

    socket.on("offer", function (broadcaster, sdp) {
      console.log("boradcaster:",broadcaster)
      _t.setState({broadcasterName: broadcaster.name });
      //aggiungo evento tizio st trasmettendo
      _t.addEvent(broadcaster.name+" sta trasmettendo...")

      rtcPeerConnections[broadcaster.id] = new RTCPeerConnection(iceServers);

      rtcPeerConnections[broadcaster.id].setRemoteDescription(sdp);

      rtcPeerConnections[broadcaster.id]
          .createAnswer()
          .then((sessionDescription) => {
          rtcPeerConnections[broadcaster.id].setLocalDescription(
              sessionDescription
          );
          socket.emit("answer", {
              type: "answer",
              sdp: sessionDescription,
              room: user.room,
          });
          });

      //mappo evento a chiusura di peer connection 
      rtcPeerConnections[broadcaster.id].oniceconnectionstatechange   = (event)=>{
        //console.log("event terminato streaming",event)
        if(event.target.iceConnectionState=='disconnected'){
          //console.log("event terminato streaming",event.target.connectionState)
          _t.addEvent("ESOPO: Sembra che tu abbia dei problemi di connessione, se non senti più nulla per favore fai refresh della pagina! ") //+broadcaster.id
          //////////////////// GESTISCE DISCONNESSIONE codice vecchio per terminare connessione in caso di evento disconected su ice /////////////
          var counter =0;
          //var itr = setInterval(()=>{
            try{
              rtcPeerConnections[broadcaster.id].restartIce()
             /* setTimeout(()=>{
                rtcPeerConnections[broadcaster.id].restartIce()
              },3000)
              */
              
              
              //window.clearInterval(itr)
              _t.addEvent("ESOPO: tentativo di riconnessione in corso")
            }catch(rrr){
              _t.addEvent("ESOPO: errore nella riconnessione al server, per favore esci e rientra dalla stanza")
            }
            counter++;

            //if(counter==10)
              //window.clearInterval(itr)
          //},5000)
          
          /*_t.addEvent(broadcaster.name+" ha terminato lo streaming")
          _t.setState({isEnded:true})
          swal.fire({
            title: 'Streaming Concluso!',
            text: 'Grazie per aver utilizzato esopo',
            icon: 'success',
            confirmButtonText: 'chiudi',
            allowOutsideClick:false
          }).then(result=>{
            if(result.value){
              //vado a homepage a chiusura
              window.location.replace('/')
            }
          })
          */

          /**/
         //////////////////////////////////////////////////////////////////////////

          
        }
      }

      rtcPeerConnections[broadcaster.id].ontrack = (event) => {
          _t.videoRef.current.srcObject = event.streams[0];

         
          //_t.videoRef.current.play()

          //console.log("event.streams[0].getAudioTracks().length",event.streams[0].getAudioTracks().length)
          //console.log("event.streams[0].getAudioTracks().length",event.streams[0].getVideoTracks().length)
          //setto flag trasmissione solo audio a true se non ho traccia video
          if(event.streams[0].getVideoTracks().length==0)
            _t.setState({isStreamingOnlyAudio:true})

          var playPromise = _t.videoRef.current.play()

          if (playPromise !== undefined) {
            playPromise.then(_ => {
              // Automatic playback started!
              // Show playing UI.
              
            })
            .catch(error => {
              // Auto-play was prevented
              // Show paused UI.
            });
          }
      };

      rtcPeerConnections[broadcaster.id].onicecandidate = (event) => {
          if (event.candidate) {
          console.log("sending ice candidate broadcaster riceve evento");
          socket.emit("candidate", broadcaster.id, {
              type: "candidate",
              label: event.candidate.sdpMLineIndex,
              id: event.candidate.sdpMid,
              candidate: event.candidate.candidate,
          });
          }
      };
    });

    socket.on("answer", function (viewerId, event) {
      rtcPeerConnections[viewerId].setRemoteDescription(
          new RTCSessionDescription(event)
      );

        //alert("viewerid per visitatore: ",viewerId)

      //mappo funzione per riconnessione per viewer 
      //---------------------------------------------------------------------------------------/
      rtcPeerConnections[viewerId].oniceconnectionstatechange   = (event)=>{
        
        //console.log("event terminato streaming",event)
        if(event.target.iceConnectionState=='disconnected'){
          //console.log("event terminato streaming",event.target.connectionState)
          //console.log("event disconnected eyeye: ",event)
          //alert("disconnessione per visitatore: ",viewerId)

          //_t.addEvent("ESOPO: Sembra che tu abbbia dei problemi di connessione, lo streaming potrebbe subire delle disconnessioni, yeye viewerid:"+viewerId)
          //////////////////// GESTISCE DISCONNESSIONE per visitatore /////////////
          var counter =0;
          //var itr = setInterval(()=>{
            try{
              
              //provo a ricreare offer 
              //setTimeout(()=>{
                rtcPeerConnections[viewerId].restartIce()

                
                rtcPeerConnections[viewerId].createOffer({iceRestart:true}).then((sessionDescription)=>{
                  rtcPeerConnections[viewerId].setLocalDescription(sessionDescription);
                  //non necessario?
                  /*socket.emit("offer", viewerId, {
                      type: "offer",
                      sdp: sessionDescription,
                      broadcaster: user,            
                  });
                  */
                  
                  
                  //console.log("ok nel ricreare offer")
                }).catch((erro)=>{console.log("err nel ricreare offer",erro)})
              //},3000)
             
              
              //window.clearInterval(itr)
              //_t.addEvent("ESOPO: tentativo di riconnessione in corso viewer")
            }catch(rrr){
              _t.addEvent("ESOPO: errore nella riconnessione al server, per favore esci e rientra dalla stanza")
            }
            counter++;

           /* if(counter==10)
              window.clearInterval(itr)
              */
          //},5000)
         
         //////////////////////////////////////////////////////////////////////////

          
        }
      }
      //----------------------------------------------------------------------------------------------------/


    });

    socket.on("handsup", function (viewerId, event) {
      //console.log("-- -- - - - -  handsup event ------ ")
      //console.log("viewerId",viewerId)
      //console.log("event",event)
      //mano alzata per domanda 
      //_t.addEvent(event+" vuole fare una domanda")
      _t.addEvent(event.username+" chiede: "+" "+event.question)
      _t.addQuestion(event.question,event.username,new Date())
      //faccio comparire animazione 
      _t.setState({isHandsUp:true})
      _t.setState({handsUpStr:event.username})
      //dopo 1 secondo nascondo 
      setTimeout(()=>{_t.setState({isHandsUp:false})},1000)
      //se è solo audio faccio comparire swal informativo con la domanda che dura 1 secondo 
      if(_t.state.isStreamingOnlyAudio){
        swal.fire({        
          icon: 'question',
          title: event.username,
          text: event.username+" chiede: "+" "+event.question,
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        })
      }

    })

    socket.on("disconnecteduser", function (viewerId, user) {
      //console.log("-- -- - - - -  handsup event ------ ")
      //console.log("viewerId",viewerId)
      //console.log("event",event)
      //mano alzata per domanda 
      _t.addEvent(user+" si è disconnesso")
      //console.log("this.state.viewers",_t.state.viewers)

      //trovo utente e lo tolgo da array 
      var indexF = _t.state.viewers.indexOf(user)
      if(indexF>-1){
        _t.state.viewers.splice(indexF,1)
      }

      _t.setState({viewers:_t.state.viewers})

      //faccio comparire animazione 
      /*_t.setState({isHandsUp:true})
      //dopo 1 secondo nascondo 
      setTimeout(()=>{_t.setState({isHandsUp:false})},1000)
*/
    })

    //evento messa in pausa 
    socket.on("triggerpause", function ( user) {
      //console.log("---------  triggerpause ------ ")
      //console.log("viewerId",viewerId)
      //console.log("event",user)
      //mano alzata per domanda 
      _t.setState({isTrackStopped:true})
      _t.addEvent(user+" ha messo in pausa lo streaming")
    })

    //evento restart da pausa 
    //evento messa in pausa 
    socket.on("triggerrestart", function ( user) {
      //console.log("-----------  triggerrestart ------ ")
      //console.log("viewerId",viewerId)
      //console.log("event",user)
      //mano alzata per domanda 
      _t.setState({isTrackStopped:false})
      _t.addEvent(user+" ha ripreso lo streaming")
    })


    //evento termine streaming generato da broadcaster
    socket.on("streamended",function(name){
      _t.addEvent(name+" ha terminato lo streaming")
      _t.setState({isEnded:true})
      swal.fire({
        title: 'Streaming Concluso!',
        text: 'Grazie per aver utilizzato esopo',
        icon: 'success',
        confirmButtonText: 'chiudi',
        allowOutsideClick:false
      }).then(result=>{
        if(result.value){
          //vado a homepage a chiusura
          window.location.replace('/')
        }
      })
    })

    /////////////////////////////////////////////////////////////////////////////////////
  }

  //gestisce connessione se presente in navigator 
  getClassSignal(){
    var classSignal = '';
    //check se ho navigator torno colore in base a numero 
    if(navigator.connection){
      if(navigator.connection.downlink>5){
        classSignal= "text-success"
      }else if(navigator.connection.downlink>3){
        classSignal= "text-warning"
      }else{
        classSignal= "text-red"
      }
      
      
    }else{
      classSignal= '';
    }
    //console.log("navigator.connection.downlink",navigator.connection.downlink)
    this.setState({bckSignal:classSignal})
  }

  //su azione click per mobile da diritto di accesso a fotocamera e microfono
  TestAudioVideo(){
    var _t = this;
    //chiedo permessi per utilizzare microfono e video 
      ///////////////////////////////////
      var fnUserMedia = ((navigator.mediaDevices && navigator.mediaDevices.getUserMedia))// || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia);

      //if(navigator.mediaDevices && !navigator.mediaDevices.enumerateDevices){
        if(fnUserMedia){
          navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(()=> {
            swal.fire('Fatto',"Ora nelle opzioni audio/video potrai selezionare la cam e microfono",'info').then(result=>{
              /*if(result.value){
                window.location.reload()
              }
              */
            })

                //navigator.mediaDevices.enumerateDevices().then(function(devices) {
                //  devices.forEach(function(device) {
                      //console.log(device.label)
                //  })
                  //mostro pannello per ricaricare pagina dopo aver preso permessi 
                  /*swal.fire('Fatto',"Cliccando ok potrai selezionare il microfono e la webcam da utilizzare dopo il refresh",'info').then(result=>{
                    if(result.value){
                      window.location.reload()
                    }
                  })
                  */
                  _t.BuildListAudioVideo()
              //}).catch(err=>{
              //  console.log("errore enumerate",err)
              //    swal.fire("Attenzione",'fallito enumerate!','error')
              //})
          }, function(err) { 
            console.log("err",err)
            swal.fire("Attenzione",'getUserMedia Failed! Prova con un altro browser come: Safari','error')
           })
        }else{
          swal.fire("Attenzione",'getUserMedia non supportata!','error')
        }
      //}else{
        //this.BuildListAudioVideo()
      //}

      //////////////////////////////////
  }

  //costruisce array per scelta input audio video e output audio 
  BuildListAudioVideo(){
    var _t = this;
    var strtoshow='';
    var videoIn =[]
    var audioIn = [];
    var audioOut = [];

    
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      swal.fire("Attenzione","sembra che il tuo browser/dispositivo non sia compatibile con le funzioni per accedere alla webcam, ricordati di dare il permesso al browser di accedere alla webcam e microfono!","info");
      
      //per IOS chiede prima permesso 
      //navigator.mediaDevices.getUserMedia({video: true,audio:true })

      return;
    }
    
    // List cameras and microphones.
    
    navigator.mediaDevices.enumerateDevices()
    .then(function(devices) {
      devices.forEach(function(device) {
        //strtoshow+=device.kind + ": " + device.label +" id = " + device.deviceId+"\n";
        //costruisco array video in ingresso 
        if(device.kind==='videoinput'){
          videoIn.push(device)
          //videoIn.push(device)
        }
        //costruisco array audio ingresso microfoni
        if(device.kind==='audioinput'){
          audioIn.push(device)
        }
        //costruisco array audio uscita 
        if(device.kind==='audiooutput'){
          audioOut.push(device)
        }

      });

      

      //setto nello stato array di dispositivi 
      _t.setState({videoIn:videoIn})
      _t.setState({audioIn:audioIn})
      _t.setState({audioOut:audioOut})

      //imposto scelta default per webcam 
      if(videoIn.length>0 &&  streamConstraints.video){
        streamConstraints.video.deviceId.exact = videoIn[0].deviceId;

        //imposto valore di select 
        _t.setState({videoDeviceId:videoIn[0].deviceId})
      }else{
        //alert("nesusna periferica video trovata")
        //swal.fire("nessuna webcam selezionata");
      }

      console.log("this.state in BuildListAudioVideo ",_t.state)
      //alert(strtoshow)
    })
    .catch(function(err) {
      swal.fire(err.name + ": " + err.message);
    });
  }

  //fa la lista dei device video per cambiare fotocamera da front a back 
  enumerateDevice(){
    navigator.mediaDevices.enumerateDevices().then((mediaDevices)=>{     
      let count = 1;
      mediaDevices.forEach(mediaDevice => {
        if (mediaDevice.kind === 'videoinput') {
          count++;
        }
      });

      return count;
    });
  }


  handleVideoChange(e){
    e.persist()
    console.log(e)
    //alert(JSON.stringify(e.target))
    this.setState({videoDeviceId:e.target.value})
    streamConstraints.video.deviceId.exact = e.target.value
  }

  //cambia da frontcamera a rear e viceversa 
  switchCamera(){
    
    const stream = this.videoRef.current.srcObject;
    stream
        .getTracks()
        .forEach((track) => {
          track.stop()
        })
        

    //modifico streamconstraints 
    if(this.state.videoIn.length>1){
      const stream = this.videoRef.current.srcObject;

      if(streamConstraints.video.deviceId.exact===this.state.videoIn[0].deviceId)
        streamConstraints.video.deviceId.exact = this.state.videoIn[2].deviceId
      else
        streamConstraints.video.deviceId.exact = this.state.videoIn[0].deviceId

      stream
          .getTracks()
          .forEach((track) => {

            navigator.mediaDevices
          .getUserMedia(streamConstraints)
          .then(function (streamn) {
            this.videoRef.current.srcObject = null
            this.videoRef.current.srcObject = streamn;

            Object.keys(rtcPeerConnections).forEach(id=>{
              //alert(id)
              rtcPeerConnections[id].replaceTrack(track, streamn)
        
              });
            })
            
          })
              
          
          
          
      
      
      /*
      if(streamConstraints.video.deviceId.exact===this.state.videoIn[0].deviceId)
        streamConstraints.video.deviceId.exact = this.state.videoIn[2].deviceId
      else
        streamConstraints.video.deviceId.exact = this.state.videoIn[0].deviceId
    

      try{
        navigator.mediaDevices
        .getUserMedia(streamConstraints)
        .then(function (streamn) {
            //this.videoElement.srcObject = stream;
            //let video = this.videoRef.current;
            this.videoRef.current.srcObject = null
            this.videoRef.current.srcObject = streamn;
            //this.videoRef.current.play()
            const stream1 = this.videoRef.current.srcObject;
            //stream1.play()
        })
      }catch(err){
        alert(err)
      }
      */
    }else{
      swal.fire('Non hai fotocamere secondarie ')
    }
            
  }

  //gestisce valorizzaazione fglag booleano solo per audio in trasmissione
  onlyAudioToggle(e){
    console.log("e",e.target.checked)
    this.setState({onlyAudio:e.target.checked})
  }

  checkBoxAudioToggle(e){
    //var chkAudio = !this.state.chkAudio;
    this.setState({chkAudio:e.target.checked})
  }

  checkBoxVideoToggle(e){
    //var chkVideo = !this.state.chkVideo;
    if(e.target.checked==false)
      this.setState({onlyAudio:!e.target.checked})
    this.setState({chkVideo:e.target.checked})
  }


  //mostra domande in popup
  ShowQuestions(e){
    //prendo gli ultimi this.state.questionsToRead da array questions e li metto assieme 
    var htmlQuestions='';
    this.state.questions.slice(-this.state.questionsToRead).forEach(item=>{
      htmlQuestions=htmlQuestions+'<br><b>'+item.user+"</b><i> "+item.description+"</i>"
    })
    if(this.state.questionsToRead>0){
      swal.fire({
        title: 'Ultime domande inserite',
        html:htmlQuestions,      
        icon: 'info',
        confirmButtonText: 'chiudi'      
      }).then(result=>{
        if(result.value){
          this.setState({questionsToRead:0})
        }
      })
    }
  }

  //apre popup con link condivisibile 
  ShareLink(e){
    var urltoshare = window.location.origin + "/#/join/"+this.state.stanza
    swal.fire({
      title: 'Condividi',
      html:'<a href="'+urltoshare+'">'+urltoshare+'</a>',      
      icon: 'info',
      confirmButtonText: 'chiudi'      
    })
  }

  //fa una domanda
  RaiseHand(e){
    e.preventDefault();

    swal.fire({
      title: "Fai una domanda",
      html:
        '<label for="swal-input-22" class="float-left">Scrivi qui la tua domanda</label>' +
        '<input name="swal-input-22" type="text" id="swal-input-22" class="form-control"></input>'             
      ,preConfirm: function () {
        return new Promise((resolve, reject) => {
          if ($("#swal-input-22").val() == "" ) {
            //reject()
            //$('#swal-input-1').val()
            swal.showValidationMessage("Campo obbligatorio");
            swal.enableButtons();
            //swal.fire('no bene')
          } else
            resolve([
              //$('#swal-input-11').val(),
              $("#swal-input-22").val(),                   
            ]);
        });
      },
      didOpen: function () {
        $("#swal-input-22").focus();
      },
  }).then(result=>{
      if(result.value){
        //invia evento di alzata di mano 
        socket.emit('handsup',{room: user.room,username:user.name,question:result.value[0]})
        //loggo invio della domanda per client 
        this.addEvent("hai inviato una domanda")
      }
  }).catch(err=>{
      //$log.log("Input non valido in requestAddClassProd",err)
  });

    /*
    swal.fire("Scrivi la tua domanda", {
      content: "input",
    })
    .then((value) => {
        //invia evento di alzata di mano 
      socket.emit('handsup',{room: user.room,username:user.name,question:value})
      //loggo invio della domanda per client 
      this.addEvent("hai inviato una domanda")
      //swal(`You typed: ${value}`);
    }); 
    */   
  }

  HandleFSC(){
    alert("cambio fullscreen")
  }

  RequestFullScreen(e){
    
    //e.preventDefault();
    var elem = document.getElementById("videoitem");


    if (elem.requestFullscreen) {
      //alert("requestFullscreen")
      //("request fullscreen")
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      //alert("mozRequestFullScreen")
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      //alert("webkitRequestFullscreen")
      if(this.state.intervalCHKPause)
        clearInterval(this.state.intervalCHKPause)

      elem.webkitRequestFullscreen();
      //se video in pausa lo faccio ripartire, serve a evitare in fullscreen iphone e tabled ios il video si blochi
      var CC = setInterval(function(){
        if(elem.paused)
          elem.play()
      },2000)

      this.setState({intervalCHKPause:CC})

    } else if (elem.msRequestFullscreen) { 
      //alert("msRequestFullscreen")
      elem.msRequestFullscreen();
    }else{
      //swal.fire("Attenzione","Funzione fulscreen non supportata dal tuo device","warning")
      //alert("test")
      //mappo evento ful screeen 
      /* Chrome, Safari and Opera */
      //alert("enterFullscreen")
      //ios su iphone 
      elem.webkitEnterFullscreen();
      elem.enterFullscreen();
     

      /*
      try{
        if(this.state.intervalCHKPause)
          clearInterval(this.state.intervalCHKPause)

        elem.enterFullscreen();

        //se video in pausa lo faccio ripartire, serve a evitare in fullscreen iphone e tabled ios il video si blochi
        var CC = setInterval(function(){
          if(elem.paused)
            elem.play().then().catch(err=>{
              //
              alert("Errore nel tentare di mandare in play il video ")
              clearInterval(CC)
            })
        },2000)

        this.setState({intervalCHKPause:CC})
      }catch(eee=>{
        alert("Errore imprevisto"+eee.message)
      })
      */

    }

  }



  //conclude lo streaming
  StopStreaming(e){
    //e.preventDefault();

    //invio comando per dire non più online 
    evt.toggleBroadcasterOnLine(this.state.stanza,false).then().catch();
    evt.stopEvent({UUID:this.state.stanza}).then().catch();

    //invio comando socket per avvisare tutti utenti
    socket.emit('streamended',{room: user.room,username:user.name})

    const stream = this.videoRef.current.srcObject;
    stream
        .getTracks()
        .forEach((track) => {
          //ferma permanentemente
          track.stop()
        })
   
    //chiudo tutte le connessioni peer rtc
    /* */
    Object.keys(rtcPeerConnections).forEach(item=>{
      rtcPeerConnections[item].close()
    })
    
    rtcPeerConnections = {};

    //console.log(rtcPeerConnections)

    
    //setto stato per chiusura 
    this.setState({isEnded:true})
    swal.fire({
      title: 'Streaming Concluso!',
      text: 'Grazie per aver utilizzato esopo',
      icon: 'success',
      confirmButtonText: 'chiudi',
      allowOutsideClick:false
    }).then(result=>{
      if(result.value){
        //vado a homepage per amministratore con lista eventi 
        window.location.replace('/#/dashboard/index')
      }
    })

  }

  //mette in pausa audio e video 
  PauseStreaming(e){
    if(e)
      e.preventDefault();

    var alreadySent = false;

    const stream = this.videoRef.current.srcObject;
    stream
        .getTracks()
        .forEach((track) => {
          //decido se mettere le tracce in stop o fare play in base allo stato 
          if(this.state.isTrackStopped != true){
            track.enabled = false
            this.setState({isTrackStopped:true})
            if(!alreadySent){
              this.addEvent("hai messo in pausa lo streaming")
              //invio segnale ws di pausa per notificare eventi
              socket.emit('triggerpause',{room: user.room,username:user.name})
            }
            alreadySent = true;
          }else{
            track.enabled = true     
            //invio segnale ws di ripresa da pausa per notificare eventi 
            
            if(!alreadySent){
              this.addEvent("hai ripreso lo streaming")
              socket.emit('triggerrestart',{room: user.room,username:user.name})
            }
            alreadySent = true;
            this.setState({isTrackStopped:false})
          }
        })
  }

  playVideo() {
    //video.src = playlist[index].src;
    let video = document.querySelector('video');
    //video.stop()
    video.play()
    .then(_ => this.updateMetadata())
    .catch(error => console.log(error.message));
  }
  
   updateMetadata() {
    //let track = playlist[index];
  
    console.log('Playing track...');
    navigator.mediaSession.metadata = new window.MediaMetadata({
      title: 'cfvdfg',
      artist: 'sdfsdf',
     // artwork: 'sdfsdf'
    });

    navigator.mediaSession.setMicrophoneActive(true);
  
    // Media is loaded, set the duration.
    //this.updatePositionState();
  }
  
  /* Position state (supported since Chrome 81) */
  
  updatePositionState() {
    if ('setPositionState' in navigator.mediaSession) {
      console.log('Updating position state...');
      let video = document.querySelector('video');
      navigator.mediaSession.setPositionState({
        duration: 12
        /*playbackRate: video.playbackRate,
        position: video.currentTime*/
      });
      
    }
  }
  

  testMediaNotification(e){

    var _t = this;
    
    navigator.mediaSession.metadata = new window.MediaMetadata({
      title: 'museoauto - streaming in corso',
      artist: 'e-sopo',      
      artwork: [{ src: 'https://museoauto.e-sopo.com/static/media/MAUTO_WHITE.d72e71c3.png',   sizes: '96x96',   type: 'image/png' }]
    });

    navigator.mediaSession.setActionHandler("play", () => {
      //audio.play();
      var aa = document.querySelector('audio');
      var vv = document.querySelector('video');
      vv.play()
      aa.play()
      /*if(_t.state.isBroadcaster)
        _t.PauseStreaming()*/
      navigator.mediaSession.playbackState = "playing";
    });

    navigator.mediaSession.setActionHandler("pause", () => {
      //audio.play();
      var aa = document.querySelector('audio');
      var vv = document.querySelector('video');
      vv.pause()
      aa.pause()
      /*if(_t.state.isBroadcaster)
        _t.PauseStreaming()*/
      navigator.mediaSession.playbackState = "none";
    });
    
    //setTimeout(()=>{
     
    //},500)
    /*if(initialized==false){
              var audioEl = document.querySelector('video');
              const track = audioContext.createMediaElementSource(audioEl);                          
              track.connect(audioContext.destination);  

              audioEl.pause()
              audioEl.play()
              initialized = true;
    }
    //this.videoRef.current.pause()
    //this.videoRef.current.play()
    //e.preventDefault()
    console.log("aggiorno notifica")
    navigator.mediaSession.metadata = new window.MediaMetadata({
      title: 'cfvdfg',
      artist: 'sdfsdf',
     // artwork: 'sdfsdf'
    });

    console.log("navigator.mediaSession.playbackState",navigator.mediaSession.playbackState)
    navigator.mediaSession.playbackState = "playing";
    
    navigator.mediaSession.setActionHandler("play", () => {
      //audio.play();
    });

    navigator.mediaSession.setPositionState(null);
    navigator.mediaSession.setMicrophoneActive(true);
*/
    console.log("aggiorno notifica")
    //this.playVideo()
    //this.updateMetadata()
     //aggiungo metadata per notifiche su briowser 
            //setTimeout(()=>{
              //e.preventDefault()
              /*var audioEl = document.querySelector('audio');
              const track = audioContext.createMediaElementSource(audioEl);                          
              track.connect(audioContext.destination);  
              */

              /*if ('mediaSession' in navigator) {
               
                //alert("ciao")
                navigator.mediaSession.metadata = new window.MediaMetadata({
                  title: "stanza",
                  artist: 'e-sopo',
                  album: 'museo auto',
                  artwork: [
                    { src: 'https://dummyimage.com/96x96',   sizes: '96x96',   type: 'image/png' },
                    { src: 'https://dummyimage.com/128x128', sizes: '128x128', type: 'image/png' },
                    { src: 'https://dummyimage.com/192x192', sizes: '192x192', type: 'image/png' },
                    { src: 'https://dummyimage.com/256x256', sizes: '256x256', type: 'image/png' },
                    { src: 'https://dummyimage.com/384x384', sizes: '384x384', type: 'image/png' },
                    { src: 'https://dummyimage.com/512x512', sizes: '512x512', type: 'image/png' },
                  ]
                });

                navigator.mediaSession.setActionHandler("play", () => {
                  //audio.play();
                });
                
                navigator.mediaSession.setActionHandler("seekto", details => {
                  //audio.currentTime = details.seekTime;
                });

                //alert("ok1")
                //navigator.mediaSession.playbackState = "playing";
                //audioContext.resume();
                
                
                console.log("navigator.mediaSession",navigator.mediaSession)
                console.log("audioContext",audioContext)
          
                
                //alert("ok2")
             
             
              }
              */
            //},4000)
  }

    joinAsViewer(e){
        //fa play su file silenzio 
        let audio = document.querySelector('audio');
        audio.play()
           
        if(e)
          e.preventDefault();

        var isValid = true
        //controllo che l'evento sia in stato isStarted = true , altrimenti non è possibile avviare l'evento 
        isValid = this.state.conditions.isStarted;

        if (this.state.nome === "" || !this.state.nome ||this.state.stanza === "" || this.state.conditions.isBroadcasterOnLine===false || isValid==false) {
          console.log("is not started ")
            if(isValid==false){
              swal.fire({
                title: 'Attenzione',
                text: 'L\'evento non è ancora stato avviato, riceverai una notifica quando l\'organizzatore avvierà la stanza!',
                icon: 'info',
                confirmButtonText: 'chiudi',
                allowOutsideClick:false
              })
            }else if(this.state.conditions.isCompleted==true){
              swal.fire({
                title: 'Evento Concluso',
                text: 'L\'evento è già stato concluso dalla guida ! ',
                icon: 'info',
                confirmButtonText: 'chiudi',
                allowOutsideClick:false
              })
            }else if(this.state.conditions.isBroadcasterOnLine===false){
              swal.fire({
                title: 'Attenzione',
                text: 'La guida deve ancora entrare nella stanza, rimani in attesa , riceverai una notifica quando la guida entrerà nella stanza!',
                icon: 'warning',
                confirmButtonText: 'chiudi',
                allowOutsideClick:false
              })/*.then(result=>{
                if(result.value){
                  //avvio timer per ottenere 
                }
              })*/
            }else{
              //alert("Inserisci un nome utente prima di entrare nella stanza");
              swal.fire({
                title: 'Attenzione',
                text: 'Inserisci un nome utente per poter entrare nello streaming!',
                icon: 'warning',
                confirmButtonText: 'chiudi',
                allowOutsideClick:false
              })
            }
          } else {
            console.log("is started")
            user = {
                room: this.state.stanza,
                name: this.state.nome,
            };

            this.setState({isBroadcaster:false})
        
            //disabilito audio per broadcaster 
            //videoElement.muted = true;
            //per iphone
            this.setState({playsInline:true})
            this.setState({isStreaming:true})
            this.setState({isMuted:false})

             //al play triggero notifiche 
             //this.videoRef.addEventListener("play", this.testMediaNotification(), false);

            //var _t = this
        
            socket.emit("register as viewer", user);
            //aggiungo evento a storico eventi locale 

            
            

          }
    }

    joinAsBroadcaster(e){
      //per mostrare blocco schermo 
      let audio = document.querySelector('audio');
      audio.play()

        e.preventDefault();
        if (this.state.nome === "" || this.state.stanza === "") {
            alert("Please type a room number and a name");
        } else {
             
            user = {
                room: this.state.stanza,
                name: this.state.nome,
            };
    
            //controllo se flaggato solo audio e in tal caso modifico streamConstraint 
            //console.log("this.state.onlyAudio",this.state.onlyAudio)
            if(this.state.onlyAudio===true)
              streamConstraints.video = false;

            //imposto audio a false in base a chkAudio
            if(this.state.chkAudio==false)
              streamConstraints.audio = false;

            //this.divSelectRoom.style = "display: none;";
            this.setState({isStreaming:true})
            this.setState({isBroadcaster:true})
            this.setState({broadcasterName:this.state.nome})
            //this.divConsultingRoom.style = "display: block;";
            //this.broadcasterName.innerText = user.name + " sta trasmettendo...";
    
            //disabilito audio per broadcaster 
            //this.videoElement.muted = true;
            this.setState({isMuted:true})
            //per iphone
            //this.videoElement.playsInline = true;
            this.setState({playsInline:true})
            var _t = this;
            navigator.mediaDevices
            .getUserMedia(streamConstraints)
            .then(function (stream) {
                //this.videoElement.srcObject = stream;
                //let video = this.videoRef.current;
                _t.videoRef.current.srcObject = stream;

               

                //determino se ho sia video che audio o solo audio 
                //console.log("broadcaster: steram.getAudioTracks().length",stream.getAudioTracks().length)
                //console.log("broadcaster: stream.getAudioTracks().length",stream.getVideoTracks().length)
                if(stream.getVideoTracks().length==0)
                  _t.setState({isStreamingOnlyAudio:true})

                //_t.videoRef.current.play()
                //window.URL.createObjectURL(stream)
                //_t.setState({streamObj:video})
                socket.emit("register as broadcaster", user.room);
                //invoco procedura per settare flag isBroadcasteronline all'interno di tabella eventi 
                evt.toggleBroadcasterOnLine(_t.state.stanza,true).then().catch()
                //aggiungo evento tizio st trasmettendo
                _t.addEvent(_t.state.nome+" sta trasmettendo...")
               
            })
            .catch(function (err) {
                console.log("An error ocurred when accessing media devices", err);
            });
        }
    }

    toggleModalQRCode(e,UUID){
      var urltoshare = window.location.origin + "/#/join/"+UUID
      console.log("urltoshare ", urltoshare)
      this.setState({URLQRcode:urltoshare})
      e.preventDefault()
      this.setState({isModalQRCode:!this.state.isModalQRCode})
    }
  

  render() {
    return (
      <>
        
        
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-lg section-shaped pb-4" >
              <div className="shape shape-style-1 shape-primary">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>
              <Container className="py-lg-md d-flex">
                <div className="col px-0">
                  <Row className="d-flex align-items-center">
                    <Col className="" md="6" lg="6">
                      {/*<h1 className="display-3 text-white">
                        Inizia da qui!{" "}
                        <span>gestisci i tuoi eventi!</span>
                      </h1>*/}
                      <p className="lead text-white">
                        {this.state.stanza&&this.state.isStreaming?'Stanza: '+this.state.conditions.Title:'Testa le potenzialità di E-SOPO!'} 
                      </p>
                      {/*
                      <div className="btn-wrapper">
                        <Button
                          className="btn-icon mb-3 mb-sm-0"
                          color="info"
                          href="https://demos.creative-tim.com/argon-design-system-react/#/documentation/alerts?ref=adsr-landing-page"
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fa fa-code" />
                          </span>
                          <span className="btn-inner--text">Components</span>
                        </Button>
                        <Button
                          className="btn-white btn-icon mb-3 mb-sm-0 ml-1"
                          color="default"
                          href="https://www.creative-tim.com/product/argon-design-system-react?ref=adsr-landing-page"
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="ni ni-cloud-download-95" />
                          </span>
                          <span className="btn-inner--text">
                            Download React
                          </span>
                        </Button>
                      </div>
                      */}
                    </Col>
                    <Col sm="12" md="6" lg="6" >
                        {!this.state.isGuest?
                        <span>
                          <Button className="float-right" onClick ={()=>{this.TestAudioVideo()}} color="primary" >
                          <i className="fa fa-settings"></i> Test
                        </Button>
                        {/*<Button className="float-right" color="primary" id="toggler">
                          <i className="fa fa-settings"></i> Opzioni
                        </Button>*/}
                        <Button className="float-right" color="primary" >
                          <i className={"fa fa-signal "+this.state.bckSignal}></i> 
                        </Button>
                        {/*<UncontrolledCollapse toggler="#toggler" className="w-100">
                          <Card className="bg-transparent shadow border-0 w-100">
                            <CardBody className="p-2">
                              <div className="row">
                                <FormGroup className="col-12 col-sm-4">
                                  <Label for="exampleSelect" className="text-white">Microfono</Label>
                                  <Input type="select" name="select" className="form-control-sm" id="exampleSelect">
                                    {this.state.audioIn.map((item,i)=>{
                                      return <option key={i} value={item.deviceId}>{item.label}</option>
                                    })}
                                  </Input>
                                </FormGroup>
                                <FormGroup className="col-12 col-sm-4">
                                  <Label for="exampleSelect1" className="text-white">Casse</Label>
                                  <Input type="select" name="select" className="form-control-sm" id="exampleSelect1">
                                    {this.state.audioOut.map((item,i)=>{
                                      return <option key={i} value={item.deviceId}>{item.label}</option>
                                    })}
                                  </Input>
                                </FormGroup>
                                <FormGroup className="col-12 col-sm-4">
                                  <Label for="exampleSelect2" className="text-white">Video</Label>
                                  <Input type="select" name="select" className="form-control-sm" id="exampleSelect2" value={this.state.videoDeviceId} onChange={this.handleVideoChange}>
                                    {this.state.videoIn.map((item,i)=>{
                                      return <option key={i} value={item.deviceId}>{item.label}</option>
                                    })}
                                  </Input>
                                </FormGroup>
                              </div>
                            </CardBody>
                          </Card>
                        </UncontrolledCollapse>*/}
                        </span>
                        :
                        <span>
                          {/* bottone per check diritti video audio */}
                          <Button className="float-right" color="primary" >
                            <i className={"fa fa-signal "+this.state.bckSignal}></i> 
                          </Button>
                        </span>
                        }
                    </Col>
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            {/* 1st Hero Variation */}
            <section className="section pb-50">
            <Container fluid>
              <Row className="justify-content-center" style={{display:(this.state.isStreaming===true?'none':'')}}>
                    <Col md="3" xs="12" sm="12">
                        <FormGroup>
                            <InputGroup className="">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                <i className="ni ni-zoom-split-in" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input onChange={(e)=>this.setState({ nome: e.target.value })} placeholder="Il tuo Nome" type="text" />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    {/*<Col md="3" xs="12" sm="12" className="mt-0">
                        <FormGroup>
                            <InputGroup className="">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                <i className="ni ni-zoom-split-in" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input readOnly onChange={(e)=>this.setState({ stanza: e.target.value })} value={this.state.stanza} placeholder="Stanza" type="text" />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    */}
                   
                    <Col md="2" xs="12"  sm="4" className="mt-0 d-none">
                      {/*<FormGroup check style={{display:!this.state.isGuest?'inherit':'none'}}>
                        <Input type="checkbox" name="check" value={this.state.onlyAudio}onChange={(event)=>{this.onlyAudioToggle(event)}} checked={this.state.onlyAudio} id="exampleCheck"/>
                        <Label for="exampleCheck" check>Usa Solo Audio <small><i>(deselezionando questa spunta, farai lo streaming con Audio e Video)</i></small></Label>
                      </FormGroup>     
                        */}

                      {/* streaming abilitato per audio  */}
                      <FormGroup check style={{display:!this.state.isGuest?'inherit':'none'}}> 
                          <Input type="checkbox" name="checkA" value={this.state.chkAudio} onChange={(event)=>{this.checkBoxAudioToggle(event)}} checked={this.state.chkAudio} id="exampleCheck1"/>
                          <Label for="exampleCheck1" check>Audio <small><i></i></small></Label>
                      </FormGroup>
                      {/* streaming abilitato per video  */}
                      <FormGroup check style={{display:!this.state.isGuest?'inherit':'none'}}>
                          <Input type="checkbox" name="checkV" value={this.state.chkVideo} onChange={(event)=>{this.checkBoxVideoToggle(event)}} checked={this.state.chkVideo} id="exampleCheck2"/>
                          <Label for="exampleCheck2" check>Video <small><i></i></small></Label>
                      </FormGroup>

                    </Col>
                    
                      {/*<Col md="12">
                        <div id="selectRoom">
                            <label htmlFor="name">Il tuo nome</label>
                            <input id="name" type="text" />
                            <label htmlFor="roomNumber">numero della stanza</label>
                            <input id="roomNumber" type="text" />
                            <button id="joinBroadcaster">Entra come broadcaster</button>
                            <button id="joinViewer">Entra come ascoltatore</button>
                        </div>

                        <div id="consultingRoom" style={{display:"none"}}>
                            <video autoPlay playsInline={true} loop></video>
                            <p id="broadcasterName"></p>
                            <ul id="viewers"></ul>
                        </div>
                    </Col>*/}
              </Row>
              <audio controls src="https://museoauto.e-sopo.com/static/media/3-hours-silence.mp3" onPlay={this.testMediaNotification} style={{width:'100%',display:'none'}} ></audio> 
              <Row className="justify-content-center" style={{display:(this.state.isStreaming===true?'none':'')}}>
                  <Col md="3" xs="12" sm="12" className="mt-0" style={{display:!this.state.isGuest?'':'none'}}>
                      <FormGroup >
                          <Button block onClick={(event) => { this.joinAsBroadcaster(event) }} size="md" disabled={(!this.state.stanza||this.state.stanza=='')||(!this.state.nome||this.state.nome=='')} color="primary" outline type="button">
                              Trasmetti
                          </Button>
                      </FormGroup>
                  </Col>
                  <Col md="3" xs="12"  sm="12" className="mt-0" style={{display:this.state.isGuest?'':'none'}}>
                      <FormGroup className="text-right"  >
                          <Button block onClick={(event) => { this.joinAsViewer(event) }} size="md" disabled={(!this.state.stanza||this.state.stanza=='')} color="default" outline type="button"> {/** ||(!this.state.nome||this.state.nome=='') */}
                              Entra 
                          </Button> 
                      </FormGroup>                       
                  </Col>
              </Row>
              {this.state.isStreaming?<Row>
                {/*<Col md="6" className="mt-0"> 
                  <FormGroup>
                      <Button onClick={(event) => { this.RaiseHand(event) }} size="sm" color="default" outline type="button">
                            Fai una domanda
                      </Button> 
                  </FormGroup> 
              </Col>*/}
              </Row>:""}
              <Row className="justify-content-md-center">
                {/* immagine onde sonore */}                
                <Col md={this.state.isStreamingOnlyAudio?12:8} xs="12"  sm="12" className="mt-0">{/*video nascosto se solo audio in trasmissione*/}
                  {this.state.isStreaming?
                      <div>                                        
                          <div className=""  style={{display:this.state.isStreamingOnlyAudio?'none':'inherit'}}> {/* video-wrap consultingroom   onfullscreenchange={this.HandleFSC}  */}
                            <video id="videoitem" autoPlay playsInline={this.state.playsInline} ref={this.videoRef} muted={this.state.isMuted} onPlay={this.testMediaNotification} loop  style={{width:'100%'}} ></video>  {/* onPlay={this.testMediaNotification} */}
                            {this.state.isHandsUp?<div id="videooverlay" className="fade-in">
                              <i className="fa fa-question fa-4x"></i>{this.state.handsUpStr}
                            </div>
                            :
                            this.state.conditions.isBroadcasterOnLine||this.state.isBroadcaster?'':
                              <span>
                                <div id="videooverlay2" className="mautopause">                              
                                </div>
                              </span>
                            }
                          </div>                           
                      </div>                     
                    :''}
                </Col>
                <Col md={this.state.isStreamingOnlyAudio?12:4} xs={this.state.isStreamingOnlyAudio?12:12} sm={this.state.isStreamingOnlyAudio?12:12} className="mt-0">
                {/*this.state.isStreaming?<p>{this.state.broadcasterName} sta trasmettendo...</p>:''*/}
                {this.state.isStreaming && !this.state.isBroadcaster?<FormGroup>
                   {/* tasto fullscreen, visibile solo in caso video */}                   
                  
                   {this.state.isStreamingOnlyAudio?'':
                      <Button id="tooltip00998877fs" onClick={(event) => { this.RequestFullScreen(event) }} size="sm" color="info" outline className="float-right mr-2 d-none" type="button">
                        <i className="fa fa-expand"></i>
                      </Button>
                   }
                   {/*<Button onClick={(event) => { this.testMediaNotification(event) }} size="sm" color="default" outline className="" type="button">
                            media test
                  </Button> */}
                      
                      <Button onClick={(event) => { this.RaiseHand(event) }} size="sm" color="default" outline className="" type="button">
                            Fai una domanda
                      </Button> 
                      {this.state.isStreamingOnlyAudio&&!this.state.isTrackStopped?<img className="img-fluid float-right" style={{height:'50px'}} alt="..." src={require("assets/img/WAVE-1s.gif")}/>:''}
                      
                  </FormGroup>:
                  this.state.isStreaming?
                  <div>
                    <FormGroup>
                      {/* utenti connessi */}
                    <span className="badge badge-primary">{this.state.viewers.length}</span>
                    {/* domande ricevute */}
                    <span onClick={(event) => { this.ShowQuestions(event) }} className="badge badge-info">{this.state.questionsToRead}</span>
                    {/* commentato tasto condividi evento
                    <Button id="tooltip94749347" onClick={(event) => { this.ShareLink(event) }} size="sm" color="info" outline className="float-right d-none" type="button">
                      <i className="fa fa-share-alt"></i>
                    </Button> 
                    <UncontrolledTooltip delay={0} target="tooltip94749347">
                      Ottieni il link per gli invitati
                    </UncontrolledTooltip>
                    */}
                    <Button id="tooltip11223344" onClick={(event) => { this.StopStreaming(event) }} size="sm" color="success" outline className="float-right" type="button">
                      <i className="fa fa-power-off"></i>
                    </Button> 
                    <Button id="tooltip00998877" onClick={(event) => { this.PauseStreaming(event) }} size="sm" color="warning" outline className="float-right mr-2" type="button">
                        {this.state.isTrackStopped?<i className="fa fa-play"></i>:<i className="fa fa-pause"></i>}
                    </Button>
                     {/* tasto qr code id="tooltip009988766" */}
                     <Button  onClick={(event) => { this.toggleModalQRCode(event, this.state.stanza) }} size="sm" color="primary" outline className="float-right mr-2" type="button">
                        <i className="fa fa-qrcode"></i>
                    </Button>
                    {/* tasto fullscreen */}
                    <Button id="tooltip00998877fs" onClick={(event) => { this.RequestFullScreen(event) }} size="sm" color="info" outline className="float-right mr-2 d-none" type="button">
                        <i className="fa fa-expand"></i>
                    </Button>
                    {/* commentato tasto gira fotocamera
                    <Button id="tooltip009988771" onClick={(event) => { this.switchCamera(event) }} size="sm" color="warning" outline className="float-right mr-2" type="button">
                        <i className="fa fa-camera"></i>
                    </Button>
                    */}
                    {this.state.isStreamingOnlyAudio&&!this.state.isTrackStopped?<img className="img-fluid float-right" style={{height:'50px'}} alt="..." src={require("assets/img/WAVE-1s.gif")}/>:''}
                    </FormGroup>
                   {/* <UncontrolledTooltip target="tooltip009988766" placement={'auto'}>
                     Mostra QR-Code
                  </UncontrolledTooltip>*/}
                    <UncontrolledTooltip target="tooltip00998877" placement={'auto'}>
                      Metti in pausa lo streaming
                    </UncontrolledTooltip>
                    <UncontrolledTooltip target="tooltip11223344" placement={'auto'}>
                      Termina lo streaming
                    </UncontrolledTooltip>                    
                    <UncontrolledTooltip target="tooltip00998877fs" placement={'auto'}>
                      Mette video in fullscreen
                    </UncontrolledTooltip>
                  </div>
                  :""}
                {/* lista eventi */}
                <ListGroup flush className="w-100">
                  {this.state.events.map((item,i)=>{
                    return <ListGroupItem key={i} className="small p-1" disabled tag="a" href="">{item.description} <span className="float-right"><i className="ni ni-watch-time"></i> {moment(item.time).format("HH:mm:ss")}</span></ListGroupItem>
                  })}                  
                </ListGroup>              
                </Col>
              </Row>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          
        </div>
        {/*modal per mostrareqrcode */}              
        <Modal isOpen={this.state.isModalQRCode} toggle={this.toggleModalQRCode} size="md">
          <ModalBody className="text-center">
                <QRCode value={this.state.URLQRcode} size={256}></QRCode>
          </ModalBody>
        </Modal>
          
          
        
        
      </>
    );
  }
}

export default Test;
