/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import {  HashRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss?v1.1.0";
import "assets/scss/custom.scss"


import Index from "views/Landing.js";
import Join from "views/Join.js";
import Login from "views/Login.js";
import Register from "views/Register.js";
import Acquista from "views/Acquista";
import Dashboard from "layouts/Dashboard.js";
/*import Landing from "views/examples/Landing.js";
import Login from "views/examples/Login.js";
import Profile from "views/examples/Profile.js";
import Register from "views/examples/Register.js";*/

ReactDOM.render(
  <HashRouter>
    <Switch>
      {/*<Route path="/" exact render={props => <Index {...props} />} />*/}
      <Route path="/join/:id" render={props => <Join {...props} />} />
      <Route path="/dashboard" render={props => <Dashboard {...props} />} />      
      <Route path="/login" exact render={props => <Login {...props} />} />
      <Route path="/register" exact render={props => <Register {...props} />} />
      <Route path="/acquista" exact render={props => <Acquista {...props} />} />
      {/*<Route
        path="/landing"
        exact
        render={props => <Landing {...props} />}
      />
      <Route path="/login" exact render={props => <Login {...props} />} />
      <Route
        path="/profile"
        exact
        render={props => <Profile {...props} />}
      />
      <Route
        path="/register"
        exact
        render={props => <Register {...props} />}
      />
      */}
      <Redirect to="/login" />
    </Switch>
  </HashRouter>,
  document.getElementById("root")
);
