/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
//import Index from "views/dashboard/Index.js";

//routes per admin
import AdminIndex from "views/dashboard/AdminIndex.js";
//import Test from "views/dashboard/TestAudioVideo.js";
import Anagrafiche from "views/dashboard/Anagrafiche";
import AnaAbbonamenti from "views/dashboard/AnagraficheAbbonamenti";
//import AnaEventi from "views/dashboard/AnagraficheEventi";
import AnaUtenti from "views/dashboard/AnagraficheUtenti";
import GestioneEventi from "views/dashboard/GestioneEventi";
import GestionePagamenti from "views/dashboard/GestionePagamenti";
import GestioneTicket from "views/dashboard/GestioneTicket"

//routes per altri utenti 
import UserIndex from "views/dashboard/UserIndex.js";
import StoricoPagamenti from "views/dashboard/StoricoPagamenti.js";
import StoricoEventi from "views/dashboard/StoricoEventi.js";
import GestioneEvento from "views/dashboard/GestioneEvento.js";
import AssistenzaUtente from "views/dashboard/AssistenzaUtente.js";

//pagina per gestire guide all'interno di abbonamento museo
import ManageGuides from "views/dashboard/GestioneGuide";


//routes per tutti
import Profile from "views/dashboard/Profile.js";

/*
import Maps from "views/examples/Maps.js";
import Register from "views/examples/Register.js";
import Login from "views/examples/Login.js";
import Tables from "views/examples/Tables.js";
import Icons from "views/examples/Icons.js";*/

var routes = [
  /*{
    path: "/",
    name: "Dashboard",
    icon: "ni ni-tv-2",
    component: Index,
    isPrivate:true,
    layout: "/dashboard"
  },*/
  {
    path: "/index",
    name: "Dashboard Utente",
    icon: "ni ni-tv-2",
    component: UserIndex,
    isPrivate:true,
    layout: "/dashboard"
  },
  {
    path: "/profile",
    name: "Profilo",
    icon: "ni ni-single-02",
    component: Profile,
    isPrivate:true,
    layout: "/dashboard"
  },
  /*{
    path: "/test",
    name: "Test",
    icon: "ni ni-bullet-list-67",
    component: Test,
    isPrivate:true,
    layout: "/dashboard"
  },*/
  //rotte per Admin portale, livello IDMenuCategory = 1
  {
    path: "/admin",
    name: "Dashboard",
    icon: "ni ni-tv-2",
    component: AdminIndex,
    isPrivate:true,
    layout: "/dashboard"
  },
  {
    path: "/gestionepagamenti",
    name: "Dashboard",
    icon: "ni ni-tv-2",
    component: GestionePagamenti,
    isPrivate:true,
    layout: "/dashboard"
  },
  {
    path: "/gestioneticket",
    name: "Dashboard",
    icon: "ni ni-support-16",
    component: GestioneTicket,
    isPrivate:true,
    layout: "/dashboard"
  },
  {
    path: "/anagrafiche",
    name: "Dashboard",
    icon: "ni ni-tv-2",
    component: Anagrafiche,
    isPrivate:true,
    layout: "/dashboard"
  },
  {
    path: "/anagraficheutenti",
    name: "Dashboard",
    icon: "ni ni-tv-2",
    component: AnaUtenti,
    isPrivate:true,
    layout: "/dashboard"
  },
  {
    path: "/anagraficheabbonamenti",
    name: "Dashboard",
    icon: "ni ni-tv-2",
    component: AnaAbbonamenti,
    isPrivate:true,
    layout: "/dashboard"
  },
  {
    path: "/anagraficheeventi",
    name: "Dashboard",
    icon: "ni ni-tv-2",
    component: GestioneEventi,
    isPrivate:true,
    layout: "/dashboard"
  },
  // rotte per altri utenti 
  {
    path: "/storicopagamenti",
    name: "Dashboard",
    icon: "ni ni-tv-2",
    component: StoricoPagamenti,
    isPrivate:true,
    layout: "/dashboard"
  },
  {
    path: "/storicoeventi",
    name: "Dashboard",
    icon: "ni ni-tv-2",
    component: StoricoEventi,
    isPrivate:true,
    layout: "/dashboard"
  },
  {
    path: "/gestioneevento", //
    name: "Gestione Evento",
    icon: "ni ni-tv-2",
    component: GestioneEvento,
    isPrivate:true,
    layout: "/dashboard"
  },
  {
    path: "/assistenzautente",
    name: "Dashboard",
    icon: "ni ni-tv-2",
    component: AssistenzaUtente,
    isPrivate:true,
    layout: "/dashboard"
  },
  {
    path: "/guides",
    name: "Guide",
    icon: "ni ni-tv-2",
    component: ManageGuides,
    isPrivate:true,
    layout: "/dashboard"
  },

  /*{
    path: "/icons",
    name: "Icons",
    icon: "ni ni-planet text-blue",
    component: Icons,
    layout: "/admin"
  },
  {
    path: "/maps",
    name: "Maps",
    icon: "ni ni-pin-3 text-orange",
    component: Maps,
    layout: "/admin"
  },*/
  
  // rotte admin

  // rotte utente

  /*,
  {
    path: "/tables",
    name: "Tables",
    icon: "ni ni-bullet-list-67 text-red",
    component: Tables,
    layout: "/admin"
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth"
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth"
  }*/
];
export default routes;
